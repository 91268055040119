import React, { useEffect, useState } from 'react';
import { gt, isNil, prop, propEq, replace } from 'ramda';
import { Button } from 'antd';
import { AppstoreAddOutlined } from '@ant-design/icons';
import { requestSubscription, useRelayEnvironment } from 'react-relay';
import { isNotNil } from 'ramda-adjunct';
import ModalCreateVersion from '../pages/offer/editOffer/ModalCreateVersion';
import { FinishedDuplicateOfferSubscriptionQuery } from '../_graphql/subscriptions/duplicateOffer/FinishedDuplicateOfferSubscription';
import ModalDuplicateOffer from './ModalDuplicateOffer';

const useCreateVersion = () => {
  const [showModal, setShowModal] = useState(false);

  return {
    showModal,
    setShowModal
  };
};

const CreateVersion = ({
  versionId,
  userCanCreateVersion,
  redirect,
  version
}) => {
  const environment = useRelayEnvironment();
  const {
    showModal,
    setShowModal
  } = useCreateVersion();

  const [itemId, setItemId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [errorDuplicateOffer, setErrorDuplicateOffer] = useState(false);
  const [statusDuplicateOffer, setStatusDuplicateOffer] = useState({
    step: 0,
    widgets: false,
    documents: false,
    rawDocuments: false,
    tags: false,
    globalVariables: false,
    folders: false,
    frames: false
  });

  const {
    isLastMajor,
    isLastMinor
  } = version;
  const canCreateVersion = isLastMinor || (isLastMajor && isLastMinor);

  useEffect(() => {
    const step = prop('step', statusDuplicateOffer);
    if (gt(step, 0)) {
      setProgress(Math.floor(step * 100));
    } else {
      setProgress(0);
    }
  }, [statusDuplicateOffer]);

  useEffect(() => {
    if (!isOpen) {
      setProgress(0);
      setStatusDuplicateOffer({
        step: 0,
        widgets: false,
        documents: false,
        rawDocuments: false,
        tags: false,
        globalVariables: false,
        folders: false,
        frames: false
      });
    } else {
      setShowModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const finishedDuplicateOffer = requestSubscription(environment, {
        subscription: FinishedDuplicateOfferSubscriptionQuery,
        variables: {},
        onNext: (response) => {
          if (response.finishedDuplicateOfferSubscription) {
            const { step, widgets, documents, rawDocuments, tags, globalVariables, folders, frames } = response.finishedDuplicateOfferSubscription;
            setStatusDuplicateOffer({
              step,
              widgets,
              documents,
              rawDocuments,
              tags,
              globalVariables,
              folders,
              frames
            });
          }
        },
        onError: (error) => {
          console.error('Subscription error:', error);
        }
      });

      return () => finishedDuplicateOffer.dispose();
    }
  }, [environment, isOpen]);

  if (!userCanCreateVersion || isNil(version) || !propEq('status', 'release', version)) {
    return null;
  }

  const onHide = () => setShowModal(false);
  const openStatusModal = () => setIsOpen(true);
  const onError = () => setErrorDuplicateOffer(true);

  return (
    <>
      <ModalCreateVersion
        versionId={versionId}
        isLastMinor={isLastMinor}
        isLastMajor={isLastMajor}
        visible={showModal}
        onHide={onHide}
        openStatusModal={openStatusModal}
        onError={onError}
        setItemId={setItemId}
      />

      <ModalDuplicateOffer
        open={isOpen}
        setIsOpen={setIsOpen}
        statusDuplicateOffer={statusDuplicateOffer}
        progress={progress}
        errorDuplicateOffer={errorDuplicateOffer}
        redirectTo={isNotNil(itemId) ? replace(':itemId', itemId)(redirect) : null}
      />

      {canCreateVersion && (
        <Button
          size="large"
          type="primary"
          icon={<AppstoreAddOutlined />}
          onClick={() => setShowModal(true)}
        >
          Créer une nouvelle version
        </Button>
      )}
    </>
  );
};

export default CreateVersion;
