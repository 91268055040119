import React, { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import { TeamOutlined } from '@ant-design/icons';
import { isNil, isEmpty } from 'ramda';
import Title from '../../../components/Title';

const ModalInviteMembers = ({
  visible,
  onCancel,
  onCreateTeam
}) => {
  const [teamName, setTeamName] = useState('');
  const onHandleSend = () => {
    setTeamName('');
    onCreateTeam(teamName);
  };

  return (
    <Modal
      title="Créer une nouvelle équipe"
      className="team-modal"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={640}
    >

      <Title
        title="Donnez un nom à votre équipe"
        icon="/icons/icon-equipe-dark.svg"
      />

      <b>{'Nom de l\'équipe'}</b>

      <Input
        placeholder="Nom de l'équipe"
        value={teamName}
        onChange={e => setTeamName(e.target.value)}
        prefix={<TeamOutlined className="w-5"/>}
        width={300}
      />

      <div className="team-modal-buttons">
        <Button onClick={onCancel}>Annuler</Button>
        <Button
          className="btn-create-team-modal"
          onClick={onHandleSend}
          type="primary"
          disabled={isNil(teamName) || isEmpty(teamName)}
        >
          Suivant
        </Button>
      </div>
    </Modal>
  );
};

ModalInviteMembers.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreateTeam: PropTypes.func.isRequired
};

export default ModalInviteMembers;
