import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, Card, Select } from 'antd';
import moment from 'moment';
import { propOr, compose, toUpper, take, pathOr, when, complement, isNil, path, map } from 'ramda';
import { TEAM_ROLES } from '../../../lib/rights';

const useRole = (member) => {
  const [role, setRole] = useState(propOr('regular', 'role', member));

  const firstChar = compose(
    toUpper,
    take(1),
    pathOr('', ['user', 'email'])
  )(member);

  const joinDate = compose(
    when(complement(isNil), date => moment(date).format('DD/MM/YYYY')),
    path(['dates', 'join'])
  )(member);

  return {
    firstChar,
    joinDate,
    role,
    setRole
  };
};

const Member = ({
  member,
  updateRole
}) => {
  const {
    firstChar,
    joinDate,
    role,
    setRole
  } = useRole(member);

  return (
    <Card
      className="member-rights"
      title={(
        <div>
          <Avatar size={64}>{firstChar}</Avatar>

          <div>
            <b>{pathOr('', ['user', 'email'], member)}</b>

            <br/>

            <span className="text-flexibranche-darkgray flex" >
              <img src="/icons/icon-calendar-check.svg" alt="calendrier" className="mr-1"/>
              Inscrit le {joinDate}
            </span>

          </div>

        </div>
      )}
    >

      <b>Rôle : &nbsp;</b>

      <Select
        placeholder="Choisir un rôle"
        value={role}
        onChange={setRole}
      >
        {map(({ label, value }) => {
          return (
            <Select.Option
              key={value}
              value={value}
            >
              {label}
            </Select.Option>
          );
        })(TEAM_ROLES)}
      </Select>

      <div>
        <Button
          type="primary"
          onClick={() => updateRole(path(['user', 'id'], member), role)}
        >
          Valider
        </Button>
      </div>

    </Card>
  );
};

Member.propTypes = {
  member: PropTypes.object.isRequired,
  updateRole: PropTypes.func.isRequired
};

export default Member;
