import { Table } from 'antd';
import { prop } from 'ramda';
import React from 'react';
import { renderDate, renderLastUpdate } from '../helpers/dates';

const TableDates = (
  <>
    <Table.Column
      title="Création"
      dataIndex="dates"
      width={400}
      render={(dates) => dates && renderDate(prop('creation', dates))}
    />
    {renderLastUpdate}
  </>
);

export default TableDates;
