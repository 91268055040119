/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FlexigetEnum = "flexigetApril" | "flexigetDefault" | "flexigetMME" | "%future added value";
export type FrequencyUnitEnum = "DAY" | "HOUR" | "MONTH" | "WEEK" | "%future added value";
export type UserRoleEnum = "admin" | "qa" | "regular" | "%future added value";
export type UserQueryVariables = {||};
export type UserQueryResponse = {|
  +user: ?{|
    +id: ?string,
    +username: ?string,
    +email: ?string,
    +status: ?string,
    +roles: ?$ReadOnlyArray<?UserRoleEnum>,
    +userId: ?string,
  |},
  +userTeam: ?{|
    +id: ?string,
    +teamId: ?string,
    +apiKey: ?string,
    +name: ?string,
    +role: ?string,
    +flexigets: ?$ReadOnlyArray<?FlexigetEnum>,
    +emailsConfig: ?$ReadOnlyArray<?{|
      +event: ?string,
      +to: ?$ReadOnlyArray<?string>,
    |}>,
    +remindersConfig: ?$ReadOnlyArray<?{|
      +event: ?string,
      +frequency: ?{|
        +value: ?number,
        +unit: ?FrequencyUnitEnum,
      |},
    |}>,
    +members: ?$ReadOnlyArray<?{|
      +id: ?string,
      +user: ?{|
        +id: ?string,
        +email: ?string,
      |},
      +role: ?string,
      +active: ?boolean,
      +dates: ?{|
        +join: ?string,
        +lastConnection: ?string,
      |},
    |}>,
  |},
  +teamInvitationsCount: ?number,
  +teams: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
    +apiKey: ?string,
  |}>,
|};
export type UserQuery = {|
  variables: UserQueryVariables,
  response: UserQueryResponse,
|};
*/


/*
query UserQuery {
  user {
    id
    username
    email
    status
    roles
    userId
  }
  userTeam {
    id
    teamId
    apiKey
    name
    role
    flexigets
    emailsConfig {
      event
      to
    }
    remindersConfig {
      event
      frequency {
        value
        unit
      }
    }
    members {
      id
      user {
        id
        email
      }
      role
      active
      dates {
        join
        lastConnection
      }
    }
  }
  teamInvitationsCount
  teams {
    id
    name
    apiKey
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "apiKey",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "event",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SessionUser",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "roles",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserTeam",
    "kind": "LinkedField",
    "name": "userTeam",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "teamId",
        "storageKey": null
      },
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "flexigets",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamEmailConfig",
        "kind": "LinkedField",
        "name": "emailsConfig",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "to",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamReminderConfig",
        "kind": "LinkedField",
        "name": "remindersConfig",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamReminderConfigFrequency",
            "kind": "LinkedField",
            "name": "frequency",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unit",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamMember",
        "kind": "LinkedField",
        "name": "members",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamMemberDates",
            "kind": "LinkedField",
            "name": "dates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "join",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastConnection",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "teamInvitationsCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Team",
    "kind": "LinkedField",
    "name": "teams",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "15ed6c6b9e248017b1e1942b20e39a27",
    "id": null,
    "metadata": {},
    "name": "UserQuery",
    "operationKind": "query",
    "text": "query UserQuery {\n  user {\n    id\n    username\n    email\n    status\n    roles\n    userId\n  }\n  userTeam {\n    id\n    teamId\n    apiKey\n    name\n    role\n    flexigets\n    emailsConfig {\n      event\n      to\n    }\n    remindersConfig {\n      event\n      frequency {\n        value\n        unit\n      }\n    }\n    members {\n      id\n      user {\n        id\n        email\n      }\n      role\n      active\n      dates {\n        join\n        lastConnection\n      }\n    }\n  }\n  teamInvitationsCount\n  teams {\n    id\n    name\n    apiKey\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '28dcb92b6272cef1dbacbe913962f4e3';

module.exports = node;
