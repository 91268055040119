import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { prop, assoc } from 'ramda';
import { Button, Input, message } from 'antd';
import { ThunderboltOutlined } from '@ant-design/icons';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import withUser from '../../../../withUser';
import withOfferRights from '../../../offer/withOfferRights';
import { Section, Title } from '../../../../components';
import UpdateRawDocumentMutation from '../../../../_graphql/mutations/documents/UpdateRawDocumentMutation';
import { errorMessage } from '../../../../utils/messageMutation';
import UpdateCacheMutation from '../../../../_graphql/mutations/UpdateCacheMutation';

const useRawDocument = ({ rawDocumentId, rawDocument, offerId }) => {
  const [state, setState] = useState(rawDocument);
  const history = useHistory();
  const isValid = isNotNilOrEmpty(prop('name', state));

  const onSave = () => {
    const callback = (successMessage) => (ok, error) => {
      if (ok && !error) {
        message.success(successMessage);
        UpdateCacheMutation({ date: new Date(), key: `documents-${offerId}` }, () => {});
        history.push(`/offre/${offerId}/edition`);
      } else {
        errorMessage();
      }
    };

    UpdateRawDocumentMutation({ rawDocumentId, filename: state.name }, callback('Le nom du document importé a bien été mis à jour.'));
  };

  return {
    ...state,
    setState,
    onSave,
    isValid
  };
};

const RawDocumentForm = ({
  rawDocument,
  offerId,
  rawDocumentId
}) => {
  const {
    name,
    setState,
    onSave,
    isValid
  } = useRawDocument({
    rawDocumentId, rawDocument, offerId
  });

  return (
    <>
      <Title
        title="Modifier le nom du document importé"
        backTo={`/offre/${offerId}/edition`}
      />
      <Section title="nom du document importé">
        <div className="flex gap-4">
          <Input
            value={name}
            placeholder="Nom du document importé"
            onChange={(e) => {
              setState(assoc('name', e.target.value));
            }}
            size="large"
          />
        </div>
      </Section>
      <Button
        onClick={onSave}
        disabled={!isValid}
        className="block mx-auto mt-4"
        type="primary"
        size="large"
        icon={<ThunderboltOutlined />}
      >
        Mettre à jour le nom
      </Button>
    </>
  );
};

export default withUser(withOfferRights(RawDocumentForm));
