import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { errorMessage, successMessage } from '../../../utils/messageMutation';
import CreateNewItemVersionMutation from '../../../_graphql/mutations/versions/CreateNewItemVersionMutation';

const useCreateVersion = (versionId, setItemId, openStatusModal, onError) => {
  const [loading, setLoading] = useState(false);

  const onCreate = (type) => () => {
    setLoading(true);
    !!openStatusModal && openStatusModal();

    CreateNewItemVersionMutation({ versionId, type }, (ok, error, itemId) => {
      setLoading(false);
      if (ok && !error) {
        successMessage('nouvelle version', 'créée avec succès', false, true);
        setItemId(itemId);
      } else {
        !!onError && onError();
        errorMessage();
      }
    });
  };

  return {
    loading,
    onCreate
  };
};

const ModalCreateVersion = ({
  visible,
  versionId,
  onHide,
  isLastMinor,
  isLastMajor,
  openStatusModal,
  onError,
  setItemId
}) => {
  const {
    loading,
    onCreate
  } = useCreateVersion(versionId, setItemId, openStatusModal, onError);

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={onHide}
    >
      {isLastMinor && (
        <Button
          onClick={onCreate('minor')}
          size="small"
          className="mr-4"
          type="primary"
          disabled={loading}
        >
          Version mineure
        </Button>
      )}

      {isLastMajor && isLastMinor && (
        <Button
          onClick={onCreate('major')}
          size="small"
          className="btn-green"
          disabled={loading}
        >
          Version majeure
        </Button>
      )}
    </Modal>
  );
};

export default ModalCreateVersion;
