/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserRoleEnum = "admin" | "qa" | "regular" | "%future added value";
export type InviteUserToTeamMutationVariables = {|
  teamId: string,
  teamName: string,
  email: string,
  role: UserRoleEnum,
|};
export type InviteUserToTeamMutationResponse = {|
  +inviteUserToTeam: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type InviteUserToTeamMutation = {|
  variables: InviteUserToTeamMutationVariables,
  response: InviteUserToTeamMutationResponse,
|};
*/


/*
mutation InviteUserToTeamMutation(
  $teamId: ID!
  $teamName: String!
  $email: String!
  $role: UserRoleEnum!
) {
  inviteUserToTeam(teamId: $teamId, teamName: $teamName, email: $email, role: $role) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "role"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamName"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "role",
        "variableName": "role"
      },
      {
        "kind": "Variable",
        "name": "teamId",
        "variableName": "teamId"
      },
      {
        "kind": "Variable",
        "name": "teamName",
        "variableName": "teamName"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "inviteUserToTeam",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteUserToTeamMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "InviteUserToTeamMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "b6b064a25f18ce5d56023c2f16b2594a",
    "id": null,
    "metadata": {},
    "name": "InviteUserToTeamMutation",
    "operationKind": "mutation",
    "text": "mutation InviteUserToTeamMutation(\n  $teamId: ID!\n  $teamName: String!\n  $email: String!\n  $role: UserRoleEnum!\n) {\n  inviteUserToTeam(teamId: $teamId, teamName: $teamName, email: $email, role: $role) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd2076d019e0112040abc5fe7b07e706f';

module.exports = node;
