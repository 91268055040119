import { commitMutation,
  graphql } from 'react-relay';
import environment from '../.';
import { basicMutationOnCompleted, onErrorMutation } from './common/common';

const mutation = graphql`
mutation InviteUserToTeamMutation($teamId: ID!, $teamName: String!, $email: String!, $role: UserRoleEnum!) {
  inviteUserToTeam(teamId: $teamId, teamName: $teamName, email: $email, role: $role) {
    ok
    error
  }
}
`;

export default ({ teamId, teamName, email, role }, done) => {
  const variables = {
    email,
    role,
    teamId,
    teamName
  };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ inviteUserToTeam }) => basicMutationOnCompleted(inviteUserToTeam, done),
      onError: error => onErrorMutation(error, done)
    }
  );
};
