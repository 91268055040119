import { Button, Table, Tag } from 'antd';
import { equals, prop } from 'ramda';
import { notEqual } from 'ramda-adjunct';
import React, { useState } from 'react';
import withUser from '../../../withUser';
import AcceptOrDeclineInvitationMutation from '../../../_graphql/mutations/teams/AcceptOrDeclineInvitationMutation';
import { QTeamInvitationsQuery } from '../../../_graphql/queries/QTeamInvitations';
import { errorNotification, successNotification } from '../../../utils/messageMutation';
import useFetchQuery from '../../../hooks/useFetchQuery';
import Loading from '../../../components/Loading';

const renderStatus = status => {
  const { color, label } = prop(status, {
    confirmed: { color: '#33bb33', label: 'Acceptée' },
    declined: { color: '#dd0000', label: 'Refusée' },
    pending: { color: '#a159b9', label: 'En attente' },
    canceled: { color: '#dddddd', label: 'Annulée' }
  });
  return <Tag color={color}>{label}</Tag>;
};

const useInvitations = (user, userRefetch) => {
  const { email } = user;
  const [loading, setLoading] = useState(false);

  const acceptOrDecline = (invitationId, action, reFetch) => () => {
    setLoading(true);

    AcceptOrDeclineInvitationMutation({ invitationId, action }, (ok, error) => {
      setLoading(false);
      if (ok && !error) {
        successNotification(`L'invitation a été ${equals(action, 'accept') ? 'acceptée' : 'refusée'} !`);
        userRefetch();
        reFetch();
      } else {
        errorNotification();
      }
    });
  };

  return {
    email,
    loading,
    acceptOrDecline
  };
};

const TeamInvite = ({ user, userRefetch }) => {
  const {
    email,
    loading,
    acceptOrDecline
  } = useInvitations(user, userRefetch);

  const { teamInvitations, isLoading, reFetch } = useFetchQuery({
    query: QTeamInvitationsQuery,
    args: { email },
    dataProp: 'teamInvitations',
    defaultData: []
  });

  if (isLoading) return <Loading />;
  return (
    <Table
      dataSource={teamInvitations}
      rowKey="id"
      loading={loading}
      pagination={false}
      scroll={{ y: 520 }}
    >
      <Table.Column
        title="Équipe"
        dataIndex="teamName"
      />
      <Table.Column
        title="Statut de l'invitation"
        dataIndex="status"
        render={renderStatus}
      />
      <Table.Column
        title="Actions"
        render={(text, { id, status }) => {
          if (notEqual(status, 'pending')) return null;

          return (
            <div className="inline-flex space-x-1">
              <Button
                type="primary"
                onClick={acceptOrDecline(id, 'accept', reFetch)}
              >
                Accepter
              </Button>
              <Button
                type="danger"
                onClick={acceptOrDecline(id, 'decline', reFetch)}
              >
                Refuser
              </Button>
            </div>
          );
        }}
      />
    </Table>
  );
};

export default withUser(TeamInvite);
