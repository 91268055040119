/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FinishedDuplicateOfferSubscriptionVariables = {||};
export type FinishedDuplicateOfferSubscriptionResponse = {|
  +finishedDuplicateOfferSubscription: ?{|
    +id: ?string,
    +step: ?number,
    +widgets: ?boolean,
    +documents: ?boolean,
    +rawDocuments: ?boolean,
    +tags: ?boolean,
    +globalVariables: ?boolean,
    +folders: ?boolean,
    +frames: ?boolean,
  |}
|};
export type FinishedDuplicateOfferSubscription = {|
  variables: FinishedDuplicateOfferSubscriptionVariables,
  response: FinishedDuplicateOfferSubscriptionResponse,
|};
*/


/*
subscription FinishedDuplicateOfferSubscription {
  finishedDuplicateOfferSubscription {
    id
    step
    widgets
    documents
    rawDocuments
    tags
    globalVariables
    folders
    frames
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DuplicateOfferResult",
    "kind": "LinkedField",
    "name": "finishedDuplicateOfferSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "step",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "widgets",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "documents",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawDocuments",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tags",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "globalVariables",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "folders",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "frames",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FinishedDuplicateOfferSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FinishedDuplicateOfferSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "2651f4186f36b555d40b458793746045",
    "id": null,
    "metadata": {},
    "name": "FinishedDuplicateOfferSubscription",
    "operationKind": "subscription",
    "text": "subscription FinishedDuplicateOfferSubscription {\n  finishedDuplicateOfferSubscription {\n    id\n    step\n    widgets\n    documents\n    rawDocuments\n    tags\n    globalVariables\n    folders\n    frames\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6406fbacd0c5b75137b7887eb7f180d8';

module.exports = node;
