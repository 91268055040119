import { always, any, both, compose, cond, equals, find, head, includes, isEmpty, join, juxt, path, pathOr, pipe, prop, propEq, T, tail, toUpper, values } from 'ramda';
import { isNotEmpty, isNotNil, toNumber } from 'ramda-adjunct';
import { SCOPE_OFFER } from '../../../../_CONST';
import { editorWidth } from './widget';

/**
 * Given a type of element and the width of one grid, return the minimum width of the element
 * @param type - The type of element you want to get the min width for.
 * @param widthOneGrid - the width of one grid.
 * @param widgetMinSize - The minimum size of the widget.
 */
const getMinWidth = (type, widthOneGrid, widgetMinSize) => {
  const minWidthPx = path(['minWidthElementOnPx', type], widgetMinSize) || 1;
  return minWidthPx / widthOneGrid;
};

/**
 * Given a type, return the name of the element that should be used to represent it
 * @param type - The type of the element.
 */
const changeTypeToElementName = (type) => compose(
  join(''),
  juxt([compose(toUpper, head), tail])
)(type);

/**
 * Create a new element with the default values of the given element type
 * @param values - The values of the element on state.
 * @param element - The element that is being added to the widget.
 * @param fixedY - the y-coordinate of the element that is being added.
 * @param elements - The elements that are available to add to the widget.
 * @param orientation - The orientation of the widget.
 * @returns A new element with the default values for the type of element.
 */
const createNewElement = (values, element, fixedY, elements, orientation) => {
  const fixedPosition = { x: 0, y: fixedY };
  return {
    type: prop('type', element),
    element: [
      elements[changeTypeToElementName(
        prop('type', element)
      )].defaultValues[0].value
    ],
    position: isEmpty(prop('contents', values)) ? { x: 0, y: 0 } : fixedPosition,
    size: { width: editorWidth(orientation), height: 'auto' },
    version: elements[changeTypeToElementName(prop('type', element))].version
  };
};

/**
 * It returns the resizable property of the element.
 * @param element - The element.
 * @param elements - The elements that are available to add to the widget.
 */
const elementResizable = (element, elements) => pathOr(false, [changeTypeToElementName(prop('type', element)), 'defaultValues', 0, 'resizable'], elements);

/**
 * It converts a string to a number.
 * @param i - Index of the element on React-Grid-Layout (string).
 */
const iStringToNumber = (i) => toNumber(i);

/* This is a dummy element that is used to show where the element is being dropped. Can show it's placeholder. */
const droppingItem = (nbColsGrid) => ({
  i: 'dropping-item', h: 8, w: nbColsGrid
});

/**
  * Given a type, return true if the type is on flexiget
  * @param type - The type of element we're looking for.
*/
const typeIsOnFlexiget = (type) => compose(
  isNotNil,
  find(
    pipe(
      values,
      any(both(() => isNotEmpty(type), includes(type)))
    )
  )
);

const commentsMode = (document) => {
  const status = propEq('scope', SCOPE_OFFER, document) ? path(['offerVersion', 'status'], document) : path(['version', 'status'], document);
  return cond([
    [equals('review'), always('review')],
    [equals('draft'), always('edition')],
    [T, always('disabled')]
  ])(status);
};

/**
 * It adds style in `<div>` tag of image element.
 * @param element - The image element that is being added to the widget.
 * @param htmlContent - The html content of the element.
 * @returns The html content of image element with the added margin style.
 * @type {function(element, string): string}
 */
const updateMarginStyleInTag = (element, htmlContent) => {
  // Retrieve the image element margin
  const margin = pathOr({
    top: 0, left: 0, bottom: 0, right: 0
  }, ['element', 0, 'margin'], element);
  const marginStyle = `margin: ${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px;`;

  // Add the margin to the <div> tag surrounding <img> tag
  return htmlContent.replace(
    /(<div style=")(.*?)(">)/,
    (match, p1, p2, p3) => {
      return `${p1}${p2}; ${marginStyle}${p3}`;
    }
  );
};

export {
  changeTypeToElementName,
  createNewElement,
  elementResizable,
  iStringToNumber,
  droppingItem,
  typeIsOnFlexiget,
  getMinWidth,
  commentsMode,
  updateMarginStyleInTag
};
