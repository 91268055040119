import React from 'react';
import { equals } from 'ramda';
import { Alert } from 'antd';
import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import ModalLoading from './ModalLoading';
import Section from './Section';

const ModalDuplicateOffer = ({
  open,
  setIsOpen,
  progress,
  statusDuplicateOffer,
  errorDuplicateOffer,
  redirectTo
}) => {
  const history = useHistory();

  const statusItem = (type) => {
    if (statusDuplicateOffer[type]) {
      return <CheckCircleFilled className="!text-green-400" />;
    } else {
      return <LoadingOutlined />;
    }
  };

  return (
    <ModalLoading
      visible={open}
      onClick={() => {
        setIsOpen(false);
        redirectTo && history.push(redirectTo);
      }}
      progress={progress}
      infoLoading={(
        <Section
          className="shadow-none"
          classNameTitle="text-flexidocs-turquoise"
          title="Compte rendu de la duplication de l'offre"
        >
          <div className="flex justify-between">
            <p>Documents édités dupliqués</p>
            {statusItem('documents')}
          </div>
          <div className="flex justify-between">
            <p>Documents importés dupliqués</p>
            {statusItem('rawDocuments')}
          </div>
          <div className="flex justify-between">
            <p>Widgets dupliqués</p>
            {statusItem('widgets')}
          </div>
          <div className="flex justify-between">
            <p>Tags dupliqués</p>
            {statusItem('tags')}
          </div>
          <div className="flex justify-between">
            <p>Variables globales dupliquées</p>
            {statusItem('globalVariables')}
          </div>
          <div className="flex justify-between">
            <p>Dossiers dupliqués</p>
            {statusItem('folders')}
          </div>
          <div className="flex justify-between">
            <p>Trames de comparaison dupliquées</p>
            {statusItem('frames')}
          </div>
          {equals(100, progress) && errorDuplicateOffer && (
            <Alert
              type="error"
              message={(
                <p>
                  {'Impossible de dupliquer correctement les éléments de l\'offre, veuillez contacter l\'équipe Flexidocs'}
                </p>
              )}
            />
          )}
        </Section>
      )}
      textProgression={`${progress}%`}
    />
  );
};

export default ModalDuplicateOffer;
