import React, { useMemo } from 'react';
import styled from 'styled-components';
import { filter, includes, prop, propEq, propOr } from 'ramda';
import withUser from '../../withUser';
import { ROLES } from '../../lib/rights';
import MenuItem from './MenuItem';

const StyledMenu = styled.div`
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  background-color: ${props => props.theme.primaryColor};
`;

const StyledLogo = styled.div`
  display: flex;
  height: 66px;
  align-items: center;
  justify-content: space-around;
  color: white;
  background-color: #003a55;
  width: 100%;
  padding: 0 16px;
  &>.logo-title{
    letter-spacing: -0.11px;
    font-size: 17px;
    font-weight: bold;
  }
`;

const menuItems = [{
  title: 'Tableau de bord',
  to: '/',
  imageSrc: '/images/icon-tab@3x.png',
  exact: true,
  isPublic: true
},
{
  title: 'Mes offres',
  to: '/offre',
  imageSrc: '/images/icon-offres@3x.png',
  exact: false,
  isPublic: false,
  authorizedRoles: [ROLES.admin, ROLES.regular, ROLES.qa]
},
{
  title: 'Gérer mes trames',
  to: '/trames',
  imageSrc: '/images/icon-comparer@3x.png',
  exact: false,
  isPublic: false,
  authorizedRoles: [ROLES.admin, ROLES.qa]
},
{
  title: 'Mon équipe',
  to: '/equipe',
  imageSrc: '/images/icon-equipe@3x.png',
  exact: false,
  isPublic: false,
  authorizedRoles: [ROLES.admin]
}];

const Container = ({ userTeam }) => {
  const currentRole = prop('role', userTeam);

  const availableMenus = useMemo(
    () =>
      filter((item) => {
        if (propEq('isPublic', true, item)) return true;
        return includes(currentRole, propOr([], 'authorizedRoles', item));
      }, menuItems),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userTeam]
  );

  return (
    <StyledMenu>
      <StyledLogo>
        <img src="/images/FASST_Flexi_Blanc.svg" width="125px" alt="Flexidocs"/>
      </StyledLogo>
      {availableMenus.map((item) => (
        <MenuItem
          key={prop('title', item)}
          to={prop('to', item)}
          title={prop('title', item)}
          imageSrc={prop('imageSrc', item)}
          exact={prop('exact', item)}
        />
      ))}
    </StyledMenu>
  );
};

export default withUser(Container);
