import React, { useState } from 'react';
import { isEmpty } from 'ramda';
import { Actions } from '../../components';
import Title from '../../components/Title';
import withUser from '../../withUser';
import useFetchQuery from '../../hooks/useFetchQuery';
import { QOffersQuery } from '../../_graphql/queries/QOffers';
import Loading from '../../components/Loading';
import OfferList from './offerList/OfferList';

const Offers = ({ userIsAdmin }) => {
  const { offers, isLoading, reFetch } = useFetchQuery({
    query: QOffersQuery,
    args: {},
    dataProp: 'offers',
    defaultData: []
  });

  if (isLoading) return <Loading />;
  return (
    <>
      {userIsAdmin && (
        <Actions
          listEmpty={isEmpty(offers)}
          actions={[
            {
              label: 'Créer une offre',
              to: '/offre/creer'
            }
          ]}
          title={(
            <Title
              title="mes offres"
              icon="/images/icon-offre-dark@2x.png"
              iconSize={[21, 18]}
              iconOffset={-2}
            />
          )}
        />
      )}
      <OfferList userCanEdit={userIsAdmin} offers={offers} setRefetch={reFetch} />
    </>
  );
};

export default withUser(Offers);
